.survey-dialog {
  padding: 15px 0;
  width: 100%;
}

.survey-dialog__toolbar {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: end;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #a3adb5;
  background-color: white;
}

.survey-dialog__toolbar svg {
  cursor: pointer;
}

.survey-dialog__content {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.survey-dialog__title {
  text-align: center;
  padding: 10px 0;
  margin: 10px 0;
}

.survey-dialog__title h1 {
  font-size: 32px;
}

.survey-dialog__selection {
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.survey-dialog-btn__container {
  width: 100%;
  display: flex;
  justify-content: end;
  position: sticky;
  bottom: 0;
  background-color: white;
}