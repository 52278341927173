.survey-result-component {
    width: 100%;
}

.survey-result-component h3 {
    font-size: 34px;
}

.survey-result-component__content {
    width: 100%;
}

.survey-result-component__content .content-element {
    display: flex;
    align-items: center;
    justify-items: center;
    width: 100%;
}

.survey-result-component__content .content-element:not(:first-child) {
    margin-top: 150px;
    margin-bottom: 125px;
    justify-content: space-around;
}

.chart-histogram {
    width: 60%;
    height: 400px;
    margin: 0 auto;
}

.chart-radar {
    height: 450px;
    width: 450px;
    display: flex;
}

.data-table {
    width: 40%;
    padding-left: 70px;
}

.data-table tr:not(:nth-child(1)) td {
    height: 40px;
}

.data-table tbody tr td:not(:first-child) {
    text-align: center;
}

@media (max-width: 960px) {
    .chart-histogram {
        width: 100%;
    }

    .chart-radar {
        width: 300px;
        height: 300px;
    }

    .data-table {
        width: 100%;
        margin-top: 100px;
        padding-left: 0;
    }

    .survey-result-component__content .content-element {
        display: block;
        margin-bottom: 0 !important;
    }

    .survey-result-component__content .content-element > * {
        margin-left: auto;
        margin-right: auto;
    }
}

/* .chart-content {
    height: 650px;
    margin: 20px 0;
    flex: 1 1 0;
}



.chart-radar {
    height: 400px;
    width: 400px;
    display: flex;
}

.chart-radar th {
    font-weight: normal;
    font-size: 0.8em;
    padding: 10px 0;
}

.chart-radar th:not(:first-child) {
    border-bottom-width: 2px;
    border-bottom-style: solid;
}

.chart-radar tr {
    font-size: 1.1em;
}

.chart-radar table tr td {
    width: 33%;
}

.chart-radar table tr td:not(:first-child) {
    text-align: center;
} */


@media print {
    .chart-histogram {
        page-break-after: always;
    }

    .chart-histogram {
        width: 50%;
        margin-left: 0;
        height: 350px;
    }

    .chart-radar {
        height: 350px;
        width: 350px;
        display: flex;
    }

    .survey-result-component__content .content-element:not(:first-child) {
        margin-top: 75px !important;
        margin-bottom: 125px;
        justify-content: space-around;
    }
}

