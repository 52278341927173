@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');

@font-face {
  font-family: 'FontAwesome';
  src: url("./assets/fonts/fontawesome-webfont.eot?v=4.7.0");
  src: url("./assets/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("./assets/fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("./assets/fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"), url("./assets/fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("./assets/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

html {
  height: 100%;
  scroll-behavior: smooth !important;
}

body {
  background: #fff;
  color: #1c1e1f;
  font-family: Open Sans, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  overflow-x: hidden;
  height: 100%;
}

img {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2368A1;
  line-height: 1.3;
}

h1 {
  font-size: 44px;
  line-height: 1.1;
}

ol,
ul {
  list-style: none;
}

p {
  margin: 20px 0;
}

strong {
  font-weight: 700;
}

@media (max-width: 29.99em) {
  p {
    hyphens: auto;
  }
}

@media (max-width: 46.24em) {
  h1 {
    font-size: 36px;
  }
}

.btn {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-user-select: none;
  /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  vertical-align: top;
  outline: none;
  display: inline-block;
  background-color: #2368A1;
  min-height: 48px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: white;
  text-align: center;
  text-decoration: none !important;
  padding: 13px 20px;
  transition: background 0.5s cubic-bezier(0.23, 1, 0.32, 1), color 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  margin: 15px;
  /* SIZE */
  /* COLORS */
  /* STATUT */
}

.btn:hover {
  background-color: #11324d;
  color: white;
}

.btn-gradient {
  background: linear-gradient(194.57deg, #8BCED0 0%, #0D93D2 47.92%, #0C7DB3 100%);
  transition: 0.3s opacity cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.btn-gradient:hover {
  opacity: .7;
}

.content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1;
}

.btn-alt {
  background-color: transparent;
  border: 1px solid #2368A1;
  color: #2368A1;
}

.btn-alt:hover {
  background-color: rgba(35, 104, 161, 0.1);
  color: #2368A1;
}

.logout-button {
  padding-left: 0 !important;
}

.btn-confirm {
  background-color: #699e24;
  padding: 13px 30px;
}

.btn-confirm:hover {
  background-color: rgba(105, 158, 36, .7);
}

@media print {
  .chart-container {
    max-height: 8cm !important;
  }
}

.chart-container>div:first-child {
  max-width: 210mm !important;
  height: 90mm !important;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto Slab', 'Open Sans', Arial, sans-serif;
  color: #0d93d2;
}

.btn-inactive {
  background-color: #aaa;
  opacity: 0.5;
  color: white;
  border: none;
  cursor: default;
}

.btn-inactive:hover {
  background-color: #aaa;
  color: white;
}