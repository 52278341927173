.result-filter {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
}

.result-filter__content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.result-filter__content p {
    font-weight: bold;
    font-size: 1.1em;
}

.result-filter .result-filter__options.open {
    display: block;
}

.result-filter .result-filter__options div p {
    margin: 0;
    font-weight: normal;
}

.result-filter .result-filter__options div {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.result-filter .result-filter__options div:hover {
    background-color: #dcf3fc;
}

.result-filter .result-filter__content div {
    height: 35px;
    transition: transform 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94);;
}

.arrow-icon {
    height: 35px;
    transition: transform 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94);;
}

.arrow-rotated {
    transform: rotate(180deg);
    transition: transform 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.result-filter .filter-button {
    width: 100%;
}

.result-filter-item {
    height: 24px;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}
