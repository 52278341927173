.survey-item {
    border: 1px solid #eee;
    padding: 12px 24px;
    margin-top: 24px;
    margin-bottom: 24px;
    border-radius: 3px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.enterprise__header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 20px;
}

.survey-item table {
    table-layout: fixed;
    margin-top: 15px;
}

.survey-item th {
    font-weight: bold;
    text-align: center;
}

td:first-child {
    text-align: left;
    font-size: 0.8em;
}

.survey-item td {
    position: relative;
    width: 200px;
    max-width: 200px;
    padding-bottom: 20px;
    text-align: left;
    text-align: center;
    font-size: 0.9em;
    min-height: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.respondent-cell svg {
    position: relative;
    background-color: #0d93d2;
    color: white;
    border-radius: 3px;
    margin-right: 3px;
    top: 5px;
}


.status-tag {
    border-radius: 5px;
    padding: 4px 6px;
    font-weight: bold;
    font-size: 0.8em;
}

.not-started {
    background-color: #736994;
    color: white;
}

.in-progress {
    background-color: #0d93d2;
    color: white;
}

.completed {
    background-color: #699e24;
    color: white;
}

.enterprise__title {
    font-size: 0.8em;
    font-weight: normal;
    color: #000;
}

.enterprise-name__title {
    font-weight: bold;
    font-size: 1.25rem;
    padding-right: 15px;
}

.btn-wrapper {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    position: relative;
}

.btn-wrapper svg {
    width: 30px;
    position: relative;
    top: 2px;
}

.action__content {
    display: flex;
    position: relative;
    padding-bottom: 20px;
    width: 200px;
    justify-content: center;
}

.action__container {
    width: 70%;
}

.button-cell {
    position: relative;
}

.test {
    position: absolute;
    bottom: 60px;
}

.mini-item__table thead tr th:first-child,  .mini-item__table tbody tr td:first-child{
    width: 25px !important;
    text-align: center;
}

@media (max-width: 900px) {
    .survey-item {
        margin: 24px 15px;
    } 

    .survey-item table {
        display: flex;
        position: relative;
    }

    .survey-item thead {
        position: relative;
        padding-right: 20px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
    }

    .survey-item tbody {
        flex-direction: row;
        padding-left: 20px;
    }

    .survey-item tr {
        display: flex;
        height: 217px;
        flex-direction: column;
    }

    .survey-item tr:not(:first-child):not(:last-child) {
        margin: 20px 0;
    }

    .survey-item td {
        min-height: 0;
        padding: 0;
        margin: 0;
        text-align: left;
        font-size: 0.9em;
        margin: 2px 0;
        min-height: 25px;
        max-width: none;
        width: 100%;
    }

    .survey-item th {
        text-align: start;
        font-size: 0.9em;
        margin: 0;
        font-weight: bold;
        min-height: 25px;
        margin: 2px 0;
    }

    .btn-wrapper {
        margin: 10px 0;
    }
}