.data-point:hover {
    cursor: pointer;
}

.data-point:hover + .data-point-tooltip{
    display: block;
}

.data-point-tooltip {
    display: none;
}