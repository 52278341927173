.compare-profile {
    padding: 15px 0;
    width: 100%;
}

.dialog__toolbar {
    width: 100%;
    display: flex;
    justify-content: end;
    padding-right: 15px;
    color: #a3adb5;
}

.dialog__toolbar svg {
    cursor: pointer;
}

.dialog__content {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.dialog__title {
    text-align: center;
    padding: 10px 0;
    margin: 10px 0;
}

.dialog__title h1 {
    font-size: 32px;
}
.dialog__selection {
    padding: 10px 0;
    justify-content: center;
}

.dialog__selection .survey-list {
    max-height: 300px;
    overflow-y: scroll;
}

.dialog-btn__container {
    width: 100%;
    display: flex;
    justify-content: end;
}
