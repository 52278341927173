.survey-list__container {
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
}

.loader {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 300px;
}

.survey-list__container {
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.survey-container {
  max-width: 1200px;
  margin: 0 auto;
}

.survey-controls {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  margin-bottom: 25px;
}

.survey-controls > * {
  flex: 1;
}

.result-count {
  flex: none;
  white-space: nowrap;
}

.paginator {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 70px;
  margin-bottom: 20px;
}

.paginator__chevron {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
}

.paginator__chevron span {
  margin-left: 0.5rem;
  line-height: 1;
  margin-top: 5px !important;
}

.paginator__chevron svg {
  vertical-align: middle;
}

.paginator__page {
  cursor: pointer;
  padding: 0.5rem;
  margin: 0 0.25rem;
}

.paginator__page.current {
  font-weight: bold;
}

.ellipsis {
  cursor: pointer;
}

.paginator__chevron .paginator__text {
  font-weight: bold;
}

.survey-title {
  font-size: 24px;
  color: #0d93d2;
  font-family: "Roboto Slab", "Open Sans", Arial, sans-serif;
  margin-bottom: 25px;
}

.survey-container {
  max-width: 1200px;
  margin: 0 auto;
}

.survey-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0;
}

.survey-title {
  font-size: 24px;
  color: #0d93d2;
  font-family: "Roboto Slab", "Open Sans", Arial, sans-serif;
}

.survey-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}

.result-count {
  white-space: nowrap;
}

.survey-list__container {
  max-width: 1250px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.survey-filter {
  font-size: 18px;
  color: #0d93d2;
  font-family: "Roboto Slab", "Open Sans", Arial, sans-serif;
  margin-right: 10px;
}

.flex-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.filter-control {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: end;
}

.filter-field {
  margin: 0 15px;
}

.filter-search-field {
  flex: 2;
}

.filter-status-field {
  flex: 1;
}

@media (max-width: 768px) {
  .survey-controls {
    flex-direction: column;
  }

  .filter-control {
    padding: 0 15px;
    justify-content: center;
  }

  .filter-field {
    margin: 0 5px;
  }

  .survey-header {
    margin: 50px 15px;
  }
}