header {
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    top: 0;
    z-index: 900;
}

.sticky {
    position: sticky;
}

.header__content {
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
}

.header__logo a {
    display: flex;
}

.header__navigation {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: end;
}

.navigation {
    display: flex;
    justify-content: flex-end;
    height: auto;
    margin-bottom: 10px;
}

.navigation li {
    margin: 0 25px;
    white-space: nowrap;
}

.navigation__link {
    position: relative;
    display: flex;
    text-decoration: none;
    color: #000;
    font-weight: bold;
    padding: 7.5px 0;
}

.navigation__link:hover {
    color: #007bff;
}

.navigation__link::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: #2368a1;
    width: 100%;
    transform-origin: right center;
    transform: scaleX(0);
    transition: transform .3s cubic-bezier(.23, 1, .32, 1);
}

.navigation__link:hover::after {
    transition-delay: .1s;
    transform-origin: left center;
    transform: scale(1);
}

.burger-btn {
    display: none;
    background-color: transparent;
    margin-right: 10px;
}

@media (max-width: 1024px) {
    .navigation li {
        font-size: 0.8em;
    }
}

@media (max-width: 900px) {
    .header__logo {
        margin-left: auto;
        margin-right: auto;
    }

    .burger-btn__content {
        display: flex;
        position: relative;
    }

    .burger-btn__text {
        transform: translateY(100%);
        position: relative;
        top: -15px;
        font-size: 10px;
        z-index: 1002;
        color: #9aa6ae;
        font-weight: bold;
    }

    .burger-btn {
        position: absolute;
        top: 25px;
        right: 7px;
        display: block !important;
        z-index: 1002;
    }

    .header__navigation {
        display: block;
    }

    .navigation {
        width: 100vw;
        overflow: hidden;
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 100%;
        height: 100vh;
        flex-direction: column;
        z-index: 1001;
        justify-content: flex-start;
        padding-top: 60px;
        transform: translateX(0%);
        transition: transform .6s cubic-bezier(0.86, 0, 0.07, 1);
    }

    .navigation.expanded {
        transform: translateX(-100%);
        transition: transform .6s cubic-bezier(0.86, 0, 0.07, 1);
    }

    .navigation li {
        font-size: 1em;
        margin: 0 35px;
        width: fit-content;
    }

    .navigation__link {
        padding: 15px 0;
    }
}



/* .c-header {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #fff;
}

@media (max-width: 46.24em) {
    .c-header {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        padding: 5px 0;
        background-color: white;
        z-index: 1000;
    }
}

.header__content {
    max-width: 1250px;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 80em) {
    .header__content {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media (max-width: 46.24em) {
    .header__logo {
        margin: auto;
    }
}

@media (min-width: 46.25em) {
    .menu__nave {
        flex: 1;
        padding-left: 20px;
        display: flex;
        flex-direction: column;
    }

    .menu__nave-top {
        order: -1;
        text-align: right;
    }

    .menu__nave-top>* {
        margin-left: 5px;
    }

    .menu__nave-main {
        margin-top: auto;
    }
}

@media (max-width: 46.24em) {
    .menu__nave {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(100%);
        overflow-y: scroll;
        padding: 60px 20px;
        background-color: white;
        transition: 0.6s transform cubic-bezier(0.86, 0, 0.07, 1);
    }

    .open-menu .menu__nave {
        transform: translateX(0);
    }

    .menu__nave-top {
        margin-top: 20px;
        padding-top: 40px;
        border-top: 1px solid #EEEEEE;
    }

    .menu__nave-top .select-rounded {
        width: 100%;
        margin: 5px 0;
    }

    .menu__nave-top .select-rounded select {
        padding: 10px 40px 10px 20px;
    }

    .menu__nave-top .btn-rounded {
        width: 100%;
        text-align: center;
        margin: 5px 0;
        padding: 10px 15px;
    }
}

.header__content {
    max-width: 1250px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
}

.menu {
    display: flex;
    padding: 1em 0;
    text-align: center;
    top: 0;
}

.menu__nav {
    align-self: flex-end;
}

.menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 1em;
}

.menu ul li {
    display: inline;
    margin-right: 25px;
    white-space: no-wrap;
}

.menu ul li a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
}

.menu ul li a:hover {
    color: #007bff;
}

.logo {
    height: 120px;
    margin-right: 20px;
}

@media (min-width: 46.25em) {
    .navigation {
        max-width: 880px;
        margin-left: auto;
        display: flex;
        justify-content: flex-end;
    }
}

.navigation__item {
    margin: 0 25px;
    white-space: nowrap;
}

@media (max-width: 68.75em) {
    .navigation__item {
        margin: 0 20px;
    }
}

@media (max-width: 61.24em) {
    .navigation__item {
        margin: 0 8px;
    }
}

@media (max-width: 46.24em) {
    .navigation__item {
        margin: 0;
    }
}

.navigation__item:first-child {
    margin-left: 0;
}

.navigation__item:last-child {
    margin-right: 0;
}



.nagivation__link {
    display: inline-block;
    position: relative;
    padding: 7.5px 0;
    color: #020303;
    font-weight: 700;
}

@media (max-width: 46.24em) {
    .nagivation__link {
        padding: 15px 0;
        margin: 0 20px;
    }
}

.nagivation__link:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: #2368A1;
    width: 100%;
    transform-origin: right center;
    transform: scaleX(0);
    transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.nagivation__link .icon {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    transition: 0.3s fill cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.is-current .nagivation__link,
.nagivation__link:hover,
.nagivation__link:focus,
.nagivation__link:active {
    color: #2368A1;
}

.is-current .nagivation__link .icon,
.nagivation__link:hover .icon,
.nagivation__link:focus .icon,
.nagivation__link:active .icon {
    fill: #2368A1;
}

.is-current .nagivation__link:after,
.nagivation__link:hover:after,
.nagivation__link:focus:after,
.nagivation__link:active:after {
    transition-delay: .1s;
    transform-origin: left center;
    transform: scale(1);
}

.nagivation__link.logout {
    color: #D7432B;
}

.nagivation__link.logout:hover,
.nagivation__link.logout:focus,
.nagivation__link.logout:active {
    color: #D7432B;
}

.nagivation__link.logout:after {
    background-color: #D7432B;
} */