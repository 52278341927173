.partner-item {
    flex: 1;
    flex: auto;
    width: 12%;
    padding: 1em;
}

.partner-logo {
    max-width: 200px;
    position: relative;
    margin: auto;
}

.partner-logo:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
}

@media (max-width: 46.24em) {
    .partner-item {
        width: 50%;
    }
    
    .partner-logo {
        width: 140px;
    }
}

.partner-logo img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 8em;
}