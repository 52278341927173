.ressources-page {
	padding: 40px 0;
  max-width: 1250px;
	margin: 0 auto;
}

.ressources-page h1 {
 font-size: 24px;   
}

.ressources-page ul {
	margin: 30px 10px;
}

.ressources-page ul li {
	position: relative;
  padding-left: 30px;
  margin: 12px 0;
}

.ressources-page ul li:before{
	content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #2368a1;
}