.home {
    position: relative;
    min-height: 100vh;
    overflow-x: hidden;
}

.block-text-image {
    position: relative;
}

.block-text-image--loud {
    overflow: hidden;
}

@media (min-width: 61.25em) {
    .block-text-image--loud:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(25%) translateY(50%) rotate(-35deg);
        background-color: #f9f9f9;
        z-index: -1;
    }
}

.block-text-image__main {
    max-width: 1250px;
    margin: auto;
    padding: 40px 0 100px;
}

@media (max-width: 80em) {
    .block-text-image__main {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media (max-width: 46.24em) {
    .block-text-image__main {
        margin-top: 80px;
        padding-bottom: 60px;
    }
}

.block-text-image__head {
    margin-bottom: 45px;
}

h1,
h2,
h3 {
    font-size: 44px;
    line-height: 1.1;
    color: #0d93d2;
    font-family: Roboto Slab, Open Sans, Arial, sans-serif;
}

@media (max-width: 46.24em) {
    .block-text-image__title {
        font-size: 36px;
    }
}

@media (min-width: 46.25em) {
    .block-text-image__content {
        display: flex;
    }
}

.block-text-image__content ul li {
    position: relative;
    padding-left: 45px;
    margin: 50px 0;
}

@media (max-width: 46.24em) {
    .block-text-image__content ul li {
        margin: 30px 0;
    }
}

.block-text-image__content ul li:before {
    content: "";
    position: absolute;
    top: -3px;
    left: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23FFFFFF' d='M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z'/%3E%3C/svg%3E");
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #94bb66;
}

@media (min-width: 46.25em) {

    .block-text-image__text,
    .block-text-image__image {
        width: 50%;
    }
}

.block-text-image__text {
    padding-right: 50px;
}

.block-text-image__text>*:first-child {
    margin-top: 0;
}

.block-text-image__button {
    display: inline-block;
}

.block-text-image__button .btn {
    margin-left: 0;
    margin-right: 0;
}

.block-text-image__image {
    margin-top: 20px;
    position: relative;
}

@media (max-width: 46.24em) {
    .block-text-image__image {
        margin-top: 40px;
    }
}

.block-text-image__picture-1,
.block-text-image__picture-2 {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
}

.block-text-image__picture-1:before,
.block-text-image__picture-2:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 62.5%;
}

.block-text-image__picture-1 img,
.block-text-image__picture-2 img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.block-text-image__picture-1 {
    margin-right: 40px;
}

.block-text-image__picture-2 {
    margin-left: auto;
    margin-top: -30%;
    max-width: 450px;
}

@media (max-width: 61.24em) {
    .block-text-image__picture-2 {
        max-width: 350px;
    }
}

@media (max-width: 46.24em) {
    .block-text-image__picture-2 {
        margin-top: -10%;
        margin-left: 40px;
    }
}

/************************* 
*                        *
*       BLOCK CTA        *
*                        *
**************************/

.block-cta {
    padding: 20px 0;
    background-color: #020303;
    color: white;
}

@media (max-width: 29.99em) {
    .block-cta {
        padding: 30px 0;
    }
}

.block-cta__main {
    max-width: 1250px;
    margin: auto;
}

@media (max-width: 80em) {
    .block-cta__main {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.block-cta__content {
    display: flex;
    padding-top: 45px;
}

@media (max-width: 46.24em) {
    .block-cta__content {
        display: block;
    }
}

.block-cta__content-text {
    flex: 1;
    padding-right: 20px;
}

@media (max-width: 46.24em) {
    .block-cta__content-text {
        padding-right: 0;
        margin-bottom: 20px;
    }
}

.block-cta__content-button {
    width: 380px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 61.24em) {
    .block-cta__content-button {
        width: auto;
    }

    .block-cta__content-button .btn {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.block-cta__content-button .btn {
    margin: 0;
}

.block-cta h2 {
    color: white;
    font-size: 32px;
    margin-bottom: 5px;
}

@media (max-width: 29.99em) {
    .block-cta h2 {
        font-size: 24px;
    }
}

.block-cta p {
    margin: 0;
    color: #8BCED0;
    font-size: 18px;
}

@media (max-width: 29.99em) {
    .block-cta p {
        font-size: 16px;
    }
}

/************************* 
*                        *
*       BLOCK WHY        *
*                        *
**************************/

.paragraph {
    overflow: hidden;
}

.paragraph__main {
    max-width: 1250px;
    margin: auto;
}

@media (max-width: 80em) {
    .paragraph__main {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.paragraph__list-item {
    margin: 100px 0;
}

@media (max-width: 46.24em) {
    .paragraph__list-item {
        margin: 50px 0;
    }
}

@media (min-width: 46.25em) {
    .paragraph-item {
        display: flex;
        margin: 0 -50px;
    }

    .paragraph-item__content,
    .paragraph-item__image {
        width: 50%;
        margin: 0 50px;
    }
}

.paragraph-item__content ul li {
    position: relative;
    padding-left: 20px;
    margin: 25px 0;
}

.paragraph-item__content ul li:before {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #2368A1;
}

.paragraph-item__title {
    font-size: 44px;
    line-height: 1.1;
    margin-bottom: 35px;
}

@media (max-width: 46.24em) {
    .paragraph-item__title {
        font-size: 36px;
    }
}

.paragraph-item__text {
    line-height: 1.8;
}

.paragraph-item__image {
    padding-top: 20px;
}

.paragraph__list-item:nth-child(even) .paragraph-item__image {
    order: -1;
}

.paragraph-item__picture {
    position: relative;
    background-color: #8BCED0;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
}

.paragraph-item__picture:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 56.25%;
}

.paragraph-item__picture img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.paragraph-item__button .btn {
    margin-left: 0;
    margin-right: 0;
}

.player-container {
    align-self: center;
    width: 40%;
}

@media (max-width: 46.24em) {
    .player-container {
        width: 100%;
    }
}

.player-container iframe {
    width: 100%;
    min-height: 300px;
}

/************************* 
*                        *
*       BLOCK HOW        *
*                        *
**************************/

.text-sidebar {
    position: relative;
    margin: 100px 0 60px;
}

.text-sidebar:after,
.text-sidebar:before {
    content: "";
    z-index: -1;
    width: auto;
    height: 1px;
    background-color: #8BCED0;
}

.text-sidebar:after {
    position: absolute;
    top: -70px;
    right: -20px;
    left: -20px;
    transform: rotate(-12deg);
}

@media (max-width: 46.24em) {
    .text-sidebar:after {
        top: -35px;
    }
}

.text-sidebar:before {
    position: absolute;
    top: -110px;
    right: -20px;
    left: -20px;
    transform: rotate(-5deg);
}

@media (max-width: 46.24em) {
    .text-sidebar:before {
        top: -55px;
    }
}

@media (max-width: 46.24em) {
    .text-sidebar {
        margin: 50px 0;
    }
}

.text-sidebar__main {
    max-width: 1250px;
    margin: auto;
}

@media (max-width: 80em) {
    .text-sidebar__main {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.text-sidebar__title {
    font-size: 44px;
    line-height: 1.1;
    margin-bottom: 15px;
}

@media (max-width: 46.24em) {
    .text-sidebar__title {
        font-size: 36px;
    }
}

@media (min-width: 46.25em) {
    .text-sidebar__content {
        display: flex;
    }

    .text-sidebar__left {
        flex: 1;
        padding-right: 50px;
    }
}

.text-sidebar__text {
    max-width: 850px;
    line-height: 1.8;
}

.text-sidebar__text ul {
    margin-bottom: 20px;
}

.text-sidebar__text ul li {
    position: relative;
    padding-left: 20px;
    margin: 25px 0;
}

.text-sidebar__text ul li:last-child {
    margin-bottom: 0;
}

.text-sidebar__text ul li:before {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #2368A1;
}

@media (min-width: 46.25em) {
    .text-sidebar__side {
        width: 270px;
    }
}

.side-block {
    position: relative;
    height: 380px;
}

@media (max-width: 46.24em) {
    .side-block {
        margin-top: 40px;
    }
}

.side-block:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(35, 104, 161, 0.9) 0%, rgba(139, 206, 208, 0.9) 100%);
}

.side-block__picture {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.side-block__picture img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.side-block__content {
    position: relative;
    z-index: 1;
    padding: 25px 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

@media (max-width: 46.24em) {
    .side-block__content {
        justify-content: center;
    }
}

.side-block__title {
    color: white;
    text-align: center;
}

@media (min-width: 46.25em) {
    .side-block__title {
        margin-top: 40px;
    }
}

@media (max-width: 46.24em) {
    .side-block__button {
        margin: 30px auto 0;
    }
}

@media (min-width: 46.25em) {
    .side-block__button {
        margin-top: auto;
    }
}

.side-block__button .btn {
    margin: 0;
    border-radius: 40px;
}

.side-block__title {
    font-size: 24px;
}

/*********************************** 
*                                  *
*       BLOCK CompanySurvey        *
*                                  *
************************************/

.text-blocks {
    position: relative;
    margin: 100px 0 40px;
}

.text-blocks:after,
.text-blocks:before {
    content: "";
    z-index: -1;
    width: auto;
    height: 1px;
    background-color: #8BCED0;
}

.text-blocks:after {
    position: absolute;
    top: 10px;
    right: -20px;
    left: -20px;
    transform: rotate(12deg);
}

.text-blocks:before {
    position: absolute;
    top: -20px;
    right: -20px;
    left: -20px;
    transform: rotate(5deg);
}

@media (max-width: 46.24em) {
    .text-blocks {
        margin: 50px 0;
    }
}

.text-blocks__main {
    max-width: 1250px;
    margin: auto;
}

@media (max-width: 80em) {
    .text-blocks__main {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.text-blocks__head {
    max-width: 850px;
}

.text-blocks__title {
    font-size: 44px;
    line-height: 1.1;
    margin-bottom: 30px;
}

@media (max-width: 46.24em) {
    .text-blocks__title {
        font-size: 36px;
    }
}

.text-blocks__list {
    display: flex;
    flex-flow: row wrap;
    margin-top: 40px;
    position: relative;
    background-color: white;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
}

.text-blocks__list:before {
    content: "";
    z-index: -1;
    width: 100vw;
    height: 300px;
    position: absolute;
    right: 50%;
    bottom: -60px;
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background-color: #f9f9f9;
}

.text-blocks__list-item {
    width: 25%;
    padding: 20px 30px;
}

.text-blocks__list-item:not(:first-child) {
    border-left: 1px solid #EEEEEE;
}

@media (max-width: 46.24em) {
    .text-blocks__list-item {
        width: 50%;
        border-bottom: 1px solid #EEEEEE;
    }
}

@media (max-width: 30em) {
    .text-blocks__list-item {
        width: 100%;
        padding-top: 30px;
        padding-bottom: 10px;
    }
}

.text-blocks__list-title {
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 40px;
}

.text-blocks__list-text {
    line-height: 1.8;
}

.text-blocks__bottom {
    margin-top: 100px;
    max-width: 850px;
}

/******************************
*                             *
*       BLOCK partners        *
*                             *
*******************************/

.block-partners {
    margin: 50px 0;
}

.block-partners__main {
    max-width: 1250px;
    margin: auto;
}

@media (max-width: 80em) {
    .block-partners__main {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.block-partners__head {
    margin-bottom: 20px;
    position: relative;
}

.block-partners__head:before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 16px;
    left: 0;
    height: 1px;
    background-color: #8BCED0;
    z-index: -1;
}

@media (max-width: 46.24em) {
    .block-partners__head:before {
        bottom: 13px;
    }
}

.block-partners__head>* {
    display: inline;
    color: #020303;
    font-size: 32px;
    background-color: white;
    padding-right: 20px;
}

@media (max-width: 46.24em) {
    .block-partners__head>* {
        font-size: 26px;
    }
}

.block-partners__content {
    display: flex;
}

@media (max-width: 61.24em) {
    .block-partners__content {
        display: block;
    }
}

.block-partners__text {
    width: 380px;
}

@media (max-width: 61.24em) {
    .block-partners__text {
        width: 100%;
    }
}

.block-partners__text .btn {
    margin: 0;
}

.block-partners__list {
    flex: 1;
    display: flex;
    padding-left: 40px;
}

@media (max-width: 61.24em) {
    .block-partners__list {
        padding-left: 0;
    }
}

@media (max-width: 46.24em) {
    .block-partners__list {
        flex-flow: row wrap;
    }
}

.block-partners__list {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 25px;
}

.block-partners__title {
    margin: 36px 0;
    font-size: 20px;
}

@media (max-width: 46.24em) {
    .block-partners__list-item {
        width: 50%;
    }
}

/******************************
*                             *
*       BLOCK FAQ             *
*                             *
*******************************/

.block-faq {
    position: relative;
    margin: 100px 0;
}

.block-faq:after,
.block-faq:before {
    content: "";
    z-index: -1;
    width: auto;
    height: 1px;
    background-color: #8BCED0;
}

.block-faq:after {
    position: absolute;
    top: -150px;
    right: -20px;
    left: -20px;
    transform: rotate(-12deg);
}

@media (max-width: 46.24em) {
    .block-faq:after {
        top: -35px;
    }
}

.block-faq:before {
    position: absolute;
    top: -190px;
    right: -20px;
    left: -20px;
    transform: rotate(-5deg);
}

@media (max-width: 46.24em) {
    .block-faq:before {
        top: -55px;
    }
}

@media (max-width: 46.24em) {
    .block-faq {
        margin: 50px 0;
    }
}

.block-faq__main {
    max-width: 1250px;
    margin: auto;
}

@media (max-width: 80em) {
    .block-faq__main {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.block-faq__head {
    max-width: 850px;
}

.block-faq__title {
    font-size: 44px;
    line-height: 1.1;
    margin-bottom: 10px;
}

@media (max-width: 46.24em) {
    .block-faq__title {
        font-size: 36px;
    }
}

.block-faq__list {
    display: flex;
    flex-flow: row wrap;
    margin-top: 10px;
    position: relative;
    background-color: white;
}

.block-faq__list-item {
    width: 50%;
}

@media (min-width: 46.25em) {
    .block-faq__list-item {
        border-left: 1px solid #EEEEEE;
        border-bottom: 1px solid #EEEEEE;
    }

    .block-faq__list-item:nth-child(1),
    .block-faq__list-item:nth-child(2) {
        border-top: 1px solid #EEEEEE;
    }

    .block-faq__list-item:nth-child(even),
    .block-faq__list-item:last-child {
        border-right: 1px solid #EEEEEE;
    }
}

@media (max-width: 46.24em) {
    .block-faq__list-item {
        width: 100%;
        border: 1px solid #EEEEEE;
    }

    .block-faq__list-item:not(:first-child) {
        border-top: 0;
    }
}

.block-faq__list-title {
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 40px;
}

.block-faq__list-text {
    line-height: 1.8;
}

.block-faq__bottom {
    margin-top: 100px;
    max-width: 850px;
}

.block-faq-item {
    padding: 30px;
    height: 100%;
}

.block-faq-item:nth-child(1) {
    z-index: 1;
}

.block-faq-item:nth-child(2) {
    z-index: 2;
}

.block-faq-item:nth-child(3) {
    z-index: 3;
}

.block-faq-item:nth-child(4) {
    z-index: 4;
}

.block-faq-item:nth-child(5) {
    z-index: 5;
}

.block-faq-item:nth-child(6) {
    z-index: 6;
}

.block-faq-item:nth-child(7) {
    z-index: 7;
}

.block-faq-item:nth-child(8) {
    z-index: 8;
}

.block-faq-item:nth-child(9) {
    z-index: 9;
}

.block-faq-item:nth-child(10) {
    z-index: 10;
}

.block-faq-item p {
    margin: 0;
}

section {
    padding: 2em 0;
}

.c-footer {
    background-color: #1c1e1f;
    color: rgba(255, 255, 255, 0.8);
    position: relative;
    margin-top: 15px;
    text-align: left;
    font-size: 16px;
    font-family: "Open Sans", Arial, sans-serif;
    line-height: 1.6;
}

.c-footer:before {
    content: "";
    position: absolute;
    top: -15px;
    right: 0;
    left: 0;
    width: 100%;
    height: 15px;
    background: linear-gradient(32.09deg, rgba(13, 147, 210, 0.9) 0%, rgba(139, 206, 208, 0.9) 100%);
}

.c-footer-main {
    max-width: 1250px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
}

.c-footer-left p {
    margin: 0;
    color: white;
    font-size: 14px;
}

.c-footer-left a {
    font-size: 14px;
    color: white;
    text-decoration: underline;
}

.c-footer-left a:hover {
    color: rgba(255, 255, 255, 0.7);
}

.c-footer-nav-menu-content {
    margin-top: 15px;
}

.c-footer-right img {
    width: 160px;
}