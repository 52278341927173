.edit-profile {
    padding: 15px 0;
    width: 100%;
}

.edit-profile__toolbar {
    width: 100%;
    display: flex;
    justify-content: end;
    padding-right: 15px;
    color: #a3adb5;
}

.edit-profile__toolbar svg {
cursor: pointer;
}

.edit-profile__content {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.edit-profile__title {
    text-align: center;
    padding: 10px 0;
    margin: 10px 0;
}

.edit-profile__title h1 {
    font-size: 32px;
}
.edit-profile__selection {
    padding: 10px 0;
    display: flex;
    justify-content: center;
}

.edit-btn__container {
    width: 100%;
    display: flex;
    justify-content: end;
}
