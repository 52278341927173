.pagination {
    margin: 15px 0;
}

.paginator {
    display: flex;
    align-items: center;
    justify-content: center;
}

.paginator__page {
    padding: 13px 20px;
    outline: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: bold;
    margin: 0 5px;
    cursor: pointer;
}

.paginator__page:hover {
    background-color: #e4f7ff;
}

.paginator__page.current {
    background-color: #0d93d2;
    color: white;
}

.paginator__chevron {
    padding: 5px 10px;
    position: relative;
}

.paginator__chevron svg {
    position: relative;
    top: 3px;
    cursor: pointer;
}

.paginator__chevron svg:hover {
    color: #0d93d2;
    position: relative;
    top: 3px;
    cursor: pointer;
}

@media (max-width: 900px) {

    .paginator__page {
        padding: 5px 7px;
    }
}