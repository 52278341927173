.highligth-bar {
    opacity: 0.7;
}

.highligth-text, .foreground-text {
    text-anchor: middle;
    font-size: 0.8em;
    font-weight: bold;
}

.highligth-text span {
    display: block;
}

.foreground-bar {
    fill: #42a7eb;
    opacity: 0.8;
}
