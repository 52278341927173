.react-joyride__beacon {
    display: none !important;
    pointer-events: none;
}

.result {
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.result__title h2 {
    color: #000;
}

.result__body {
    margin-top: 50px;
}

.result h3 {
    font-size: 20px;
    margin: 36px 0;
}

.filter-options {
    border: 1px solid #fafafa;
}

.filter-options ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.filter-options li {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter-options li:hover {
    background-color: #dcf3fc;
    cursor: pointer;
}

.filter-options li span {
    padding: 18px;
    font-weight: bold;
    font-size: 1.1em;
    padding-right: 5px;
}

.filter-options li svg {
    color: #0c7db3;
}

.options {
    position: relative;
    margin-top: 15px;
    display: flex;
    justify-content: right;
}

.resssource__container {
    max-width: 1060px;
    margin: 50px auto;
    overflow: hidden;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@media (min-width: 46.25em) {
    .resssource__container {
        display: flex;
        min-height: 330px;
    }
}

.resssource__container:hover {
    transform: scale(1.025);
}

.box-left,
.box-right {
    position: relative;
}

@media (min-width: 46.25em) {

    .box-left,
    .box-right {
        width: 50%;
    }
}

.box-right:before {
    content: "";
    position: absolute;
    top: -5px;
    bottom: -100px;
    left: 0;
    transform: rotate(10deg);
    transform-origin: top left;
    width: 100px;
    height: auto;
    background-color: white;
}


@media (min-width: 46.25em) {
    .box-picture {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

@media (max-width: 46.24em) {
    .box-picture {
        position: relative;
    }

    .box-picture:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 56.25%;
    }
}

.box-picture img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.box-picture img {
    transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.resssource__container:hover .box-picture img {
    transform: scale(1.05);
}

.box-text {
    position: relative;
    padding: 60px;
}

@media (max-width: 46.24em) {
    .box-text {
        padding: 30px;
    }
}

.box-text p {
    font-size: 20px;
}

.box-text .btn {
    margin: 20px 0 0;
}

.info-tip.open {
    opacity: 1;
    top: -30px;
    transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
}

.info-tip {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6ffed;
    border: 1px solid green;
    border-radius: 5px;
    width: 70px;
    height: 35px;
    position: absolute;
    top: -20px;
    right: 70px;
    opacity: 0;
    transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
}

.filter-global-option {
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
}

.filter-reset {
    color: #d7432b;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    min-height: 65px;
}

@media (max-width: 960px) {
    .result {
        overflow-x: hidden;
    }
}

@media (max-width: 768px) {
    .result__content {
        margin: 0 15px;
    }

    .result__title {
        text-align: center;
    }

    .chart-content {
        display: block;
    }

    .radar-container {
        max-width: none;
    }

    .chart-histogram {
        width: 100%;
    }

    .radar-container canvas {
        margin: auto;
    }

    .chart-radar table {
        display: table;
        width: 100%;
    }

    .filter-options ul {
        flex-wrap: wrap;
    }

    .filter-options ul li{
        justify-content: start;
    }

    /* .chart-radar table thead {
        width: 100%;
        display: table-row;
    }

    .chart-radar table tr {
        display: table-row;
    }

    .chart-radar table thead th, .chart-radar table tbody td {
        width: 33%;
    } */
}