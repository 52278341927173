.error-page {
    max-width: 1250px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 0;
}

.error-page p {
    text-align: center;
    padding: 50px 0;
}

.error-page button {
    max-width: 250px;
    margin: 0 auto;
}