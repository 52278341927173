.how-to-result {
    max-width: 1060px;
    margin: auto;
}

.how-to-result h3 {
    margin: 0;
    font-size: 20px;
}

.chapter {
    padding-top: 0px;
}

.chapter__container {
    margin-top: 50px;
}

.chapter ul li {
    margin-left: 1em;
}

.chapter ul li h4 {
    color: #0d93d2;
    font-weight: 600;
}

.chapter table {
    width: 100%;
    border-collapse: collapse;
}

.chapter table thead tr {
    border-bottom: 2px solid black;
}

.chapter table th:first-child {
    width: 16%;
}

.chapter table th {
    padding: 0.5em;
}

.chapter table th:first-child {
    background-color: #ebf6fd;
}

.chapter table tbody tr {
    border-bottom: 1px solid #76c2e5;
}

.chapter table tr * {
    text-align: left;
    padding-left: 1em;
    color: #0d93d2;
}

.chapter table tbody tr *:first-child {
    padding-top: 1em;
}

.chapter table tr span {
    display: block;
}

.chapter table tbody tr td:first-child {
    font-size: 1.1em;
    font-weight: bold;
    background-color: #ebf6fd;
}

.chapter table tbody tr:last-child {
    border-bottom: none;
}