.print-container {
    width: 80%;
    margin: 0 auto;
    size: A4 portrait;
    font-family: 'Roboto', 'Open Sans', Arial, sans-serif;
}

.page {
    max-height: 257mm;
    height: 257mm;
    overflow: hidden;
    font-size: 80% !important;
}

.multipages {
    margin-bottom: 15mm;
    font-size: 80% !important;
    page-break-after: always;
}

.text-center {
    text-align: center;
}

h1.text-center, .multipages h1, .page h1 {
    font-size: 34px;
}

h2.text-center {
    font-size: 24px;
}

.text-center img {
    max-width: 6cm;
}

.color-black {
    color: black;
}

.print-break-before {
    page-break-before: always;
}

.my-average {
    position: relative !important;
    bottom: unset !important;
    top: unset !important;
    left: 30mm !important;
    height: inherit !important;
    width: inherit !important;
    margin-top: 0mm !important;
    margin-bottom: 15mm !important;
    text-align: left !important;
}

.score-container {
    padding: 30px;
    margin-bottom: 30px;
    background-color: #f9f9f9;
}

.score-table {
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
    border: none;
}

.score-table .distribution-cell {
    border-bottom: 1px solid #eee;
    padding: 0 0 .5rem 0;
}

.score-table .distribution-cell-score {
    text-align: right;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    padding: 0.5rem 0;
}

.axis-legend-explain {
    margin-bottom: 2em;
}

.axis-legend-title {
    margin-top: 1em;
    font-size: 14px;
}

.axis-legend-shift {
    margin-left: 1cm;
}

.response-table .th-score {
    text-align: center;
    font-size: 12px;
    width: 80px;
}

.response-table .th-score svg {
    width: 50%;
}

.response-table .td-score {
    font-weight: bold;
    text-align: center;
}

.response-table .td-answer-label {
    text-align: left;
}

.response-table .tr-theme td {
    border: none;
    padding: 0;
}

.response-table .tr-theme span {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    display: block;
    width: 100%;
    border: 1px solid #0d93d2;
    color: #0d93d2;
}

.response-table .tr-question {
    border-top: 1px dashed #eee;
}

.response-table .question {
    font-weight: bold;
    font-size: 14px;
    background-color: #ccc !important;
    padding: 0.5em;
    width: 100%;
}

.response-table .answered {
    font-weight: bold;
    color: #0d93d2;
}

.response-table td {
    border-left: 1px solid #ccc;
    padding: 0.5em;
    vertical-align: middle;
}

.response-table th {
    border-left: 1px solid #ccc;
}

.response-table .axis-scoring {
    padding: 0.5em 0;
}

.response-table .axis-scoring svg {
    width: 60%;
}

.response-table .axis-scoring.fail svg {
    filter: invert(30%) sepia(54%) saturate(2042%) hue-rotate(342deg) brightness(101%) contrast(91%);
    width: 60%;
}

.response-table .axis-scoring.success svg {
    filter: invert(49%) sepia(41%) saturate(736%) hue-rotate(44deg) brightness(102%) contrast(90%);
}

.axis-legend {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1cm;
    margin-bottom: 1cm;
}

.axis-legend>div {
    display: flex;
    align-items: center;
}

.axis-legend svg {
    height: 1cm;
    margin: 0 5px 0 10px;
}

.answer-description {
    font-style: italic;
}

.page .chart-radar {
    width: 300px;
    height: 300px;
} 

.page .content-element {
    margin-top: 50px !important;
    justify-content: start !important;
} 

.page .chart-histogram {
    margin: 0;
    width: 400px;
    height: 250px;
}

@media print {
    .noprint > * {
        display: none;
    }

    .multipages {
        page-break-after: always;
    }

    .page {
        page-break-after: always;
        break-inside: avoid;
    }

    .score-container tr {
        break-inside: avoid;
    }

    .response-table {
        break-inside: avoid;
    }

    .response-table .th-score {
        font-size: 8px;
        padding: 0 5px;
        width: 40px;
    }

    .page .content-element {
        justify-content: start !important;
        display: flex;
        column-gap: 20px;
        margin-top: 0;
        font-size: 9px;
    }
}

@page {
    size: A4 portrait;
    margin-top: 70px;
    margin-bottom: 50px;
}
